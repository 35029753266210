/* stylelint-disable selector-class-pattern */
@import 'styles/style.css';

@font-face {
  font-family: 'Product Sans';
  src: url('/font/product-sans/ProductSansRegular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: bold;
  src: url('/font/product-sans/ProductSansBoldItalic.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  font-weight: bold;
  src: url('/font/product-sans/ProductSansBold.ttf');
  font-display: swap;
}

// 兼容一下登录模块中的字体，因为登录模块的Title字体是 Product Sans Bold
@font-face {
  font-family: 'Product Sans Bold';
  font-weight: bold;
  src: url('/font/product-sans/ProductSansBold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Product Sans';
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url('/font/Poppins-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: SSFProDisplay;
  font-style: normal;
  font-weight: 700;
  src: url('/font/SFProDisplay-Bold.ttf') format('truetype');
}

@font-face {
  font-family: SSFProDisplay;
  font-style: normal;
  font-weight: 400;
  src: url('/font/SFProDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: SSFProDisplay;
  font-style: normal;
  font-weight: 300;
  src: url('/font/SFProDisplay-Light.ttf') format('truetype');
}

@font-face {
  font-family: SSFProDisplay;
  font-style: normal;
  font-weight: 500;
  src: url('/font/SFProDisplay-Medium.ttf') format('truetype');
}

@font-face {
  font-family: SSFProDisplay;
  font-style: normal;
  font-weight: 600;
  src: url('/font/SFProDisplay-Semibold.ttf') format('truetype');
}

a {
  color: inherit;
  text-decoration: none;
}

/* stylelint-disable-next-line selector-id-pattern */
html,
#next-app {
  width: 100%;
  min-height: 100%;
  cursor: default;

  // background-color: #f5f6fa;
  background-color: #fff;
}

::selection {
  background-color: #CCE0FF !important;
}

* {
  box-sizing: border-box;
}

html,
body,
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
  // add by PM【Frannie】 at 2025.1.7
  font-family: SSFProDisplay !important;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body::-webkit-scrollbar {
  width: 0 !important;
}

ol,
ul,
dl {
  padding: 0;
  margin: 0;
  margin-bottom: 0 !important;
}

.css-ayyqgs-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: inherit !important;
}

.MuiAutocomplete-popper {
  font-size: inherit !important;

  .MuiAutocomplete-listbox {
    font-size: 12px !important;

    li {
      font-size: 12px !important;

      div {
        font-size: 12px !important;
      }
    }
  }

  .MuiAutocomplete-noOptions {
    font-size: 12px !important;
  }
}

@media (max-width: 799px) {
  .popMOdalContainer {
    .MuiPaper-elevation {
      top: 54px !important;
      left: 0 !important;
      max-width: 100% !important;
      height: calc(100% - 54px) !important;
      box-shadow: 0 !important;
    }
  }
}

html.modal-active,
html.modal-active body {
  /* exclude padding from the height */
  box-sizing: border-box;

  /* -1px prevents IOS safari footer from being revealed */
  height: calc(var(--window-inner-height));

  /* block hidden content to disable scroll */
  overflow: hidden;
}